/* fonts */

@font-face {
  font-family: "Gotham Pro";
  src: url("../font/GothamPro.eot");
  src: local("Gotham Pro"), local("GothamPro"),
    url("../font/GothamPro.eot?#iefix") format("embedded-opentype"),
    url("../font/GothamPro.woff2") format("woff2"),
    url("../font/GothamPro.woff") format("woff"),
    url("../font/GothamPro.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Gotham Pro";
  src: url("../font/GothamPro-Bold.eot");
  src: local("Gotham Pro Bold"), local("GothamPro-Bold"),
    url("../font/GothamPro-Bold.eot?#iefix") format("embedded-opentype"),
    url("../font/GothamPro-Bold.woff2") format("woff2"),
    url("../font/GothamPro-Bold.woff") format("woff"),
    url("../font/GothamPro-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
}

/* variables */

:root {
  --grey: #c4c4c4;
  --dark-grey: #6d6e71;
  --orange: #f37021;
  --darkGray: #00000070;
}

/* default styles */

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: "Gotham Pro";
  user-select: none;
  scroll-behavior: smooth;
}

a {
  text-decoration: none;
  color: var(--dark-grey);
}

a:hover {
  color: var(--orange);
}

.header-button {
  position: relative;
  padding: 0.7rem 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.header-button:hover {
  color: var(--orange);
}

.header-button::after {
  content: " ";
  left: 0;
  position: absolute;
  width: 2rem;
  height: 100%;
  border: 1px solid var(--dark-grey);
  border-right: none;
  border-top-left-radius: 20%;
  border-bottom-left-radius: 20%;
}
.header-button::before {
  content: " ";
  position: absolute;
  right: 0;
  width: 2rem;
  height: 100%;
  border: 1px solid var(--dark-grey);
  border-left: none;
  border-top-right-radius: 20%;
  border-bottom-right-radius: 20%;
}

/* .header-button:hover {
  color: red;
} */
.header-button:hover::after,
.header-button:hover::before {
  border-color: var(--orange);
}

input {
  margin-bottom: 2rem;
  padding: 0.5rem;
  background-color: transparent;
  border: none;
  border-bottom: 1px solid var(--dark-grey);
}

textarea {
  margin: 1rem 0;
  padding: 0.5rem;
  font-size: 16px;
  resize: none;
  border-radius: 0.4rem;
  border-color: var(--dark-grey);
  background-color: transparent;
}

button {
  color: var(--dark-grey);
  cursor: pointer;
  border: none;
  outline: none;
  white-space: nowrap;
  border-radius: 0.3rem;
  background-color: transparent;
}

input:focus,
textarea:focus {
  outline: none;
  border-color: var(--orange);
}

li {
  font-size: 0.95rem;
  list-style-type: none;
}

/* yandex-maps */

.ymaps-2-1-79-map,
.ymaps-2-1-79-inner-panes {
  border-radius: 10px;
}

/* Navbar */

.navbar {
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 90%;
  margin: auto;
}

.nav-menu {
  display: flex;
  align-items: center;
}

.menu-icon {
  display: none;
}

.hr {
  height: 1px;
  background-color: var(--dark-grey);
}

.hr-vertical {
  margin: 0 3rem;
  border: none;
  border-left: 1px solid #6d6e71;
}

.whitespace-nowrap {
  white-space: nowrap;
}

.whitespace-pre {
  white-space: pre-wrap;
}

.cursor-pointer {
  cursor: pointer;
}

/* border */

.border-none {
  border: none;
}

.rounded-sm {
  border-radius: 0.25rem;
}

.rounded-md {
  border-radius: 0.5rem;
}

/* coloring */

.bg-black {
  background-color: rgb(42, 42, 42);
}

.bg-black-08 {
  background: rgba(0, 0, 0, 0.8);
}

.bg-white {
  background-color: white;
}

.bg-grey {
  background-color: var(--grey);
}

.bg-darkGrey {
  background-color: var(--dark-grey);
}

.bg-darkGray {
  background-color: var(--darkGray);
}

.bg-orange {
  background-color: var(--orange);
}

.hover\:bg-orange:hover {
  background-color: #f37021;
}

.bg-img {
  background-image: url("../icons/sliderBG.jpg");
  background-size: cover;
}

.bg-building {
  background-image: url("../backgrounds/building_2.jpg");
  background-size: cover;
}

.bg-buildingImg {
  background-image: url("../backgrounds/building.jpg");
  background-size: cover;
}

.bg-boyImg {
  background-image: url("../backgrounds/boy.jpg");
  background-size: cover;
}

.text-white {
  color: white;
}

.text-darkGrey {
  color: var(--dark-grey);
}

.text-orange {
  color: var(--orange);
}

.hover\:text-orange:hover {
  color: var(--orange);
}

.overflow-hidden {
  overflow: hidden;
}

/* sizing */

.h-100vh {
  height: 100vh;
}

/* width (percent) */

.w-screen {
  width: 100vw;
}

.w-20 {
  width: 20%;
}

.w-30 {
  width: 30%;
}

.w-4\/12 {
  width: calc(4 / 12 * 100%);
}

.w-40 {
  width: 40%;
}

.w-50 {
  width: 50%;
}

.w-60 {
  width: 60%;
}

.w-70 {
  width: 70%;
}

.w-80 {
  width: 80%;
}

.w-full {
  width: 100%;
}

/* width (pixels & rems) */
.w-4 {
  width: 1rem;
}

.w-8 {
  width: 2rem;
}

.max-w-36rem {
  max-width: 36rem;
}

.w-20px {
  width: 20px;
}

.w-30px {
  width: 30px;
}

.w-40px {
  width: 40px;
}

.w-90px {
  width: 90px;
}

.w-100px {
  width: 100px;
}

.w-10rem {
  width: 10rem;
}

.w-25rem {
  width: 25rem;
}

.w-30rem {
  width: 30rem;
}

.w-40rem {
  width: 40rem;
}

/* height (percent) */

.h-50vh {
  height: 50vh;
}

.h-60vh {
  height: 60vh;
}

.h-90vh {
  height: 90vh;
}

.h-10 {
  height: 10%;
}

.h-20 {
  height: 20%;
}

.h-50 {
  height: 50%;
}

.h-60 {
  height: 60%;
}

.h-80 {
  height: 80%;
}

.h-90 {
  height: 90%;
}

.h-full {
  height: 100%;
}

/* height (pixels & rems)*/

.h-4 {
  height: 1rem;
}

.h-8 {
  height: 2rem;
}

.h-20rem {
  height: 20rem;
}

.h-70vh {
  height: 70vh;
}

/* positioning */

.centered {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.centered-img {
  position: absolute;
  top: 45%;
  left: 45%;
  transform: translate(-45%, -45%);
}

.transform-50 {
  transform: translate(-50%, -50%);
}

/* containers */

.container {
  width: 70%;
  margin: auto;
}

.container-80 {
  width: 80%;
  margin: auto;
}

/* gapps */

.gap-4 {
  gap: 1rem;
}

.gap-8 {
  gap: 2rem;
}

/* positioning */

.relative {
  position: relative;
}

.fixed {
  position: fixed;
}

.absolute {
  position: absolute;
}

.left-0 {
  left: 0;
}

.top-0 {
  top: 0;
}

.top-4 {
  top: 1rem;
}

.top-5 {
  top: 5%;
}

.top-50 {
  top: 50%;
}

.right-0 {
  right: 0;
}

.right-4 {
  right: 1rem;
}

.top-4 {
  top: 1rem;
}

.left-50p {
  left: 50%;
}

.left-full {
  left: 100%;
}

.flex {
  display: flex;
}

.flex-col {
  flex-direction: column;
}

.justify-center {
  justify-content: center;
}

.justify-between {
  justify-content: space-between;
}

.justify-evenly {
  justify-content: space-evenly;
}

.justify-around {
  justify-content: space-around;
}

.items-center {
  align-items: center;
}

.justify-left {
  justify-content: left;
}

.justify-end {
  justify-content: end;
}

.p-1 {
  padding: 0.25rem;
}

.p-2 {
  padding: 0.5rem;
}

.p-4 {
  padding: 1rem;
}

.p-8 {
  padding: 2rem;
}

.p-12 {
  padding: 3rem;
}

.p-16 {
  padding: 4rem;
}

.px-4 {
  padding-right: 1rem;
  padding-left: 1rem;
}

.px-8 {
  padding-right: 2rem;
  padding-left: 2rem;
}

.px-24 {
  padding-right: 6rem;
  padding-left: 6rem;
}

.py-2 {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.py-4 {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.py-16 {
  padding-top: 4rem;
  padding-bottom: 4rem;
}

.m-20 {
  margin: 5rem;
}

.m-2 {
  margin: 0.5rem;
}

.mx-4 {
  margin-left: 1rem;
  margin-right: 1rem;
}

.mx-12 {
  margin-right: 3rem;
  margin-left: 3rem;
}

.my-2 {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

.my-4 {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.my-8 {
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.my-12 {
  margin-top: 3rem;
  margin-bottom: 3rem;
}

.my-16 {
  margin-top: 4rem;
  margin-bottom: 4rem;
}

.mb-2 {
  margin-bottom: 0.5rem;
}

.mb-3 {
  margin-bottom: 0.75rem;
}

.mb-4 {
  margin-bottom: 1rem;
}

.mb-8 {
  margin-bottom: 2rem;
}

.mb-12 {
  margin-bottom: 3rem;
}

.mb-16 {
  margin-bottom: 4rem;
}

.mb-20 {
  margin-bottom: 5rem;
}

/* text sizing */

.fs-15px {
  font-size: 15px;
}

.fs-18px {
  font-size: 18px;
}

.fs-24px {
  font-size: 24px;
}

.fs-4 {
  font-size: 1rem;
}

.fs-5 {
  font-size: 1.25rem;
}

.fs-6 {
  font-size: 1.5rem;
}

.fs-8 {
  font-size: 2rem;
  line-height: 1.15;
}

.fs-2rem {
  font-size: 2rem;
}

.fs-4rem {
  font-size: 4rem;
}

.fs-4_5rem {
  font-size: 4.5rem;
}

.fs-5rem {
  font-size: 5rem;
}

.fw-600 {
  font-weight: 600;
}

.bold {
  font-weight: bold;
}

.text-center {
  text-align: center;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.outline-none {
  outline: none;
}

.zIndex-1 {
  z-index: 1;
}

.zIndex-10 {
  z-index: 10;
}

.flex-wrap {
  flex-wrap: wrap;
}

/* swiper settings */

.swiper-button-prev::after {
  display: none;
}

.swiper-button-next::after {
  display: none;
}

.swiper-button-next {
  background-image: url("../icons/right.png");
  background-repeat: no-repeat;
  background-size: 100% auto;
  background-position: center;
}

.swiper-button-prev {
  background-image: url("../icons/left.png");
  background-repeat: no-repeat;
  background-size: 100% auto;
  background-position: center;
}

.circle-img {
  background-image: url("../icons/advantages.svg");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

.icon-left {
  transform: rotate(45deg);
  border-left: 1px solid #6d6e71;
  border-bottom: 1px solid #6d6e71;
}

.icon-top {
  transform: rotate(135deg);
  background-color: transparent;
  border: 1px solid #6d6e71;
  border-top: none;
  border-right: none;
}

.icon-div:hover > .icon-top {
  border: 1px solid var(--orange);
  border-top: none;
  border-right: none;
}

.icon-div:hover > p {
  color: var(--orange);
}

.opacity-1 {
  opacity: 1;
}

.hover\:shadow-lg:hover {
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.bordered {
  border: 1px solid var(--dark-grey);
}

.rounded-md {
  border-radius: 0.5rem;
  transition: all 0.5s linear;
}

.shadow {
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.shadow-md {
  box-shadow: rgba(0, 0, 0, 0.25) 0px 14px 28px,
    rgba(0, 0, 0, 0.22) 0px 10px 10px;
}

.loader {
  scale: 2;
  width: 60px;
  height: 40px;
  position: relative;
  display: inline-block;
  --base-color: #6e6e6e; /*use your base color*/
}
.loader::before {
  content: "";
  left: 0;
  top: 0;
  position: absolute;
  width: 36px;
  height: 36px;
  border-radius: 50%;
  background-color: #fff;
  background-image: radial-gradient(
      circle 8px at 18px 18px,
      var(--base-color) 100%,
      transparent 0
    ),
    radial-gradient(
      circle 4px at 18px 0px,
      var(--base-color) 100%,
      transparent 0
    ),
    radial-gradient(
      circle 4px at 0px 18px,
      var(--base-color) 100%,
      transparent 0
    ),
    radial-gradient(
      circle 4px at 36px 18px,
      var(--base-color) 100%,
      transparent 0
    ),
    radial-gradient(
      circle 4px at 18px 36px,
      var(--base-color) 100%,
      transparent 0
    ),
    radial-gradient(
      circle 4px at 30px 5px,
      var(--base-color) 100%,
      transparent 0
    ),
    radial-gradient(
      circle 4px at 30px 5px,
      var(--base-color) 100%,
      transparent 0
    ),
    radial-gradient(
      circle 4px at 30px 30px,
      var(--base-color) 100%,
      transparent 0
    ),
    radial-gradient(
      circle 4px at 5px 30px,
      var(--base-color) 100%,
      transparent 0
    ),
    radial-gradient(
      circle 4px at 5px 5px,
      var(--base-color) 100%,
      transparent 0
    );
  background-repeat: no-repeat;
  box-sizing: border-box;
  animation: rotationBack 3s linear infinite;
}
.loader::after {
  content: "";
  left: 35px;
  top: 15px;
  position: absolute;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background-color: #fff;
  background-image: radial-gradient(
      circle 5px at 12px 12px,
      var(--base-color) 100%,
      transparent 0
    ),
    radial-gradient(
      circle 2.5px at 12px 0px,
      var(--base-color) 100%,
      transparent 0
    ),
    radial-gradient(
      circle 2.5px at 0px 12px,
      var(--base-color) 100%,
      transparent 0
    ),
    radial-gradient(
      circle 2.5px at 24px 12px,
      var(--base-color) 100%,
      transparent 0
    ),
    radial-gradient(
      circle 2.5px at 12px 24px,
      var(--base-color) 100%,
      transparent 0
    ),
    radial-gradient(
      circle 2.5px at 20px 3px,
      var(--base-color) 100%,
      transparent 0
    ),
    radial-gradient(
      circle 2.5px at 20px 3px,
      var(--base-color) 100%,
      transparent 0
    ),
    radial-gradient(
      circle 2.5px at 20px 20px,
      var(--base-color) 100%,
      transparent 0
    ),
    radial-gradient(
      circle 2.5px at 3px 20px,
      var(--base-color) 100%,
      transparent 0
    ),
    radial-gradient(
      circle 2.5px at 3px 3px,
      var(--base-color) 100%,
      transparent 0
    );
  background-repeat: no-repeat;
  box-sizing: border-box;
  animation: rotationBack 4s linear infinite reverse;
}
@keyframes rotationBack {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(-360deg);
  }
}

/* media queries */

@media (max-width: 1060px) {
  .nav-menu {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100vh;
    position: absolute;
    top: -100vh;
    left: 0;
    opacity: 1;
    transition: all 0.5s ease;
  }

  .nav-menu.active {
    background-color: white;
    top: 80px;
    left: 0;
    opacity: 1;
    transition: all 0.5s ease;
    z-index: 1;
  }

  .menu-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 20px;
    right: 20px;
  }

  ul li {
    margin-top: 1rem;
    margin-bottom: 1rem;
    font-size: 0.8rem;
    margin-left: 0.5rem;
    margin-right: 0.5rem;
  }

  .lg\:hidden {
    display: none;
  }

  .lg\:relative {
    position: relative;
  }

  .lg\:container-90 {
    width: 90%;
    margin: auto;
  }

  .lg\:w-10\/12 {
    width: calc(10 / 12 * 100%);
  }

  .lg\:mb-4 {
    margin-bottom: 1rem;
  }

  .lg\:mb-8 {
    margin-bottom: 2rem;
  }

  .lg\:w-30 {
    width: 30%;
  }

  .lg\:w-40 {
    width: 40%;
  }

  .lg\:w-60 {
    width: 60%;
  }

  .lg\:w-70 {
    width: 70%;
  }

  .lg\:w-80 {
    width: 80%;
  }

  .lg\:w-full {
    width: 100%;
  }

  .lg\:w-60px {
    width: 60px;
  }

  .lg\:w-10rem {
    width: 10rem;
  }

  .lg\:w-20rem {
    width: 20rem;
  }

  .lg\:flex-col {
    flex-direction: column;
  }

  .lg\:justify-center {
    justify-content: center;
  }

  .lg\:items-start {
    align-items: start;
  }

  .lg\:items-center {
    align-items: center;
  }

  .lg\:text-center {
    text-align: center;
  }

  .lg\:text-right {
    text-align: right;
  }

  .lg\:h-60vh {
    height: 60vh;
  }

  .lg\:fs-4 {
    font-size: 1rem;
  }

  .lg\:fs-5 {
    font-size: 1.25rem;
  }

  .lg\:fs-3rem {
    font-size: 3rem;
  }

  .lg\:shadow {
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  }

  .lg\:h-20rem {
    height: 20rem;
  }

  .lg\:mx-4 {
    margin-left: 1rem;
    margin-right: 1rem;
  }

  .lg\:my-8 {
    margin-top: 2rem;
    margin-bottom: 2rem;
  }

  .lg\:px-4 {
    padding-left: 1rem;
    padding-right: 1rem;
  }
}

@media (max-width: 1059px) {
  .md\:hidden {
    display: none;
  }

  .md\:relative {
    position: relative;
  }

  .md\:text-center {
    text-align: center;
  }

  .md\:mx-auto {
    margin-left: auto;
    margin-right: auto;
  }

  .md\:container-90 {
    width: 90%;
    margin: auto;
  }

  .md\:w-full {
    width: 100%;
  }

  .md\:w-4\/5 {
    width: 80%;
  }

  .md\:w-10\/12 {
    width: calc(10 / 12 * 100%);
  }

  .md\:fs-2rem {
    font-size: 2rem;
  }

  .md\:flex-col {
    flex-direction: column;
  }

  .md\:px-0 {
    padding-left: 0rem;
    padding-right: 0rem;
  }

  .md\:px-12 {
    padding-left: 3rem;
    padding-right: 3rem;
  }

  .md\:my-0 {
    margin-top: 0rem;
    margin-bottom: 0rem;
  }

  .md\:my-4 {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }

  .md\:h-30 {
    height: 30%;
  }

  .md\:h-70 {
    height: 70%;
  }

  .md\:fs-3 {
    font-size: 0.75rem;
  }

  .md\:fs-4 {
    font-size: 1rem;
  }

  .md\:left-0 {
    left: 0;
  }
}

@media (max-width: 500px) {
  .sm\:hidden {
    display: none;
  }

  .sm\:justify-center {
    justify-content: center;
  }

  .sm\:items-center {
    align-items: center;
  }

  .sm\:text-center {
    text-align: center;
  }

  .sm\:w-90 {
    width: 90%;
  }

  .sm\:w-full {
    width: 100%;
  }

  .sm\:px-4 {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .sm\:my-12 {
    margin-top: 3rem;
    margin-bottom: 3rem;
  }

  .sm\:mb-2 {
    margin-bottom: 0.5rem;
  }

  .sm\:fs-2rem {
    font-size: 2rem;
  }
}
